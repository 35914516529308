body {
	margin: 0;
	padding: 0;
	height: 100%;
	background-color: rgb(255, 242, 241);
	color: rgb(57, 61, 63);
	font-family: 'Ledger', serif;
}
header {
	margin: 0;
	background-color: rgb(56, 228, 174);
	color: rgb(2, 60, 64);
	font-family: 'Molle', cursive;
	font-weight: 400;
	font-style: italic;
	z-index: 999;
	position: fixed;
	width: 100%;
	text-shadow: 0.1rem 0.1rem 0.5rem rgb(255, 242, 241);
}
h1 {
	margin: 0;
	padding: 0.2rem 0 0 3rem;
	font-size: 2rem;
	font-weight: 400;
	font-style: italic;
}
h2 {
	margin: 0;
	padding-top:.3rem;
}
h3 {
	margin: 0;
	padding: 0 0.5rem 0.2rem 0;
}
button {
	border: none;
	background: transparent;
	font-size: 2rem;
	color: rgb(2, 60, 64);
	-webkit-transition: -webkit-transform 0.4s ease-in-out;
	transition: -webkit-transform 0.4s ease-in-out;
	transition: transform 0.4s ease-in-out;
	transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
	outline: none;
	padding: 0;
	text-shadow: 0.1rem 0.1rem 0.5rem rgb(255, 242, 241);
}
button:active {
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
}
nav ul {
	list-style: none;
	padding: 0 1rem;
	margin: 5rem 0 0 0;
}
li {
	padding: 0.5rem 0;
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
  min-height: calc(100vh - 54px);

}
.header-text {
	font-weight: 400;
	font-style: italic;
}
.nav-menu {
	display: flex;
	flex-direction: column;
	padding: 5rem 0 0 0;
	font-size: 1.4rem;
	position: fixed;
	height: 100%;
	text-shadow: 0.1rem 0.1rem 0.5rem rgb(255, 242, 241);
	background: -webkit-linear-gradient(
		bottom,
		rgba(93, 253, 203, 0.8),
		rgba(56, 228, 174, 0.8)
	);
	background: linear-gradient(
		to top,
		rgba(93, 253, 203, 0.8),
		rgba(56, 228, 174, 0.8)
	);
	z-index: 900;
}
.mobile-link {
	padding: .5rem;
	-webkit-transition: color .4s ease-in-out;
	transition: color .4s ease-in-out;
}
.mobile-current-page {
	color: rgb(255, 242, 241);
	-webkit-transition: color .4s ease-in-out;
	transition: color .4s ease-in-out;

} 
a {
	color: rgb(2, 60, 64);
	text-decoration: none;
	font-weight: bolder;
	-webkit-transition: color 0.1s ease-in-out,
		text-shadow 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
	transition: color 0.1s ease-in-out,
		text-shadow 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
	transition: transform 0.4s ease-in-out, color 0.1s ease-in-out,
		text-shadow 0.4s ease-in-out;
	transition: transform 0.4s ease-in-out, color 0.1s ease-in-out,
		text-shadow 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
	outline: none;
}
a:active {
	color: rgb(255, 242, 241);
}
/* .slider {
	position: relative;
	max-width: 300px;
	max-height: 300px;
	margin: 0;

	overflow: hidden;
	white-space: nowrap;
	border-radius: 5%;
	border: .1rem solid rgb(2, 60, 64);
} */

.slider-frame {
	position: relative;
}

.slide {
	display: inline-block;
	height: 100%;
	width: 100%;
	z-index: 90;
	align-items: center;
	padding: .35rem 0 0 0;
	background-color: rgb(93, 253, 203);
	
}
.slide-pic {
	width: 96%;
	height: 96%;
	align-content: center;
	margin: .5% 2% ;
	border-radius: 4%;
}
.nextArrow {
	position: absolute;
	top: 47%;
	right: 25px;
	z-index: 99;
	color: rgb(93, 253, 203);
	font-size: 2rem;
	text-shadow: -0.1rem 0.1rem 0.1rem black;
	-webkit-transition: text-shadow 0.5s ease-in-out;
	transition: text-shadow 0.5s ease-in-out;
}
.backArrow {
	position: absolute;
	top: 47%;
	left: 25px;
	z-index: 99;
	color: rgb(93, 253, 203);
	font-size: 2rem;
	text-shadow: 0.1rem 0.1rem 0.1rem black;
	-webkit-transition: text-shadow 0.5s ease-in-out;
	transition: text-shadow 0.5s ease-in-out;
}
.backArrow:active,
.nextArrow:active {
	text-shadow: none;
}
.show-frame {
	margin: 0 2rem;
}
.shown {
	-webkit-transition: margin 0.5s ease-in-out;
	transition: margin 0.5s ease-in-out;
}
.hidden {
	margin-left: -100%;
	-webkit-transition: margin 0.5s ease-in-out;
	transition: margin 0.5s ease-in-out;
}
.full-nav {
	display: none;
}
.title {
	display: flex;
	justify-content: space-between;
}
.mobile-nav {
	margin: 2rem 0 0 0.5rem;
	flex: 1 1;
}
.mobile-spacer {
	flex:1 1;
}
.title-full {
flex: 7 1;
text-align: center;
}
.contact-list {
	text-align: center;
	margin: 0;
}
.contact-list i {
	font-size: 3rem;
	margin: 0.5rem 0 0 0;
}

@media (min-width: 700px) {
	header {
		display: flex;
		flex-direction: column;
		align-items: center;
    justify-content: flex-end;
	}
	h1 {
		font-size: 4rem;
		padding: 0 1rem;
	}
	h3 {
		font-size: 2em;
		padding: 1rem 0 0 1rem;
	}
	a:hover {
		text-shadow: 0.1rem 0.1rem 0.5rem rgb(255, 242, 241),
			-0.1rem -0.1rem 0.5rem rgb(255, 242, 241),
			-0.1rem -0.1rem 0.5rem rgb(255, 242, 241),
			0.1rem 0.1rem 0.5rem rgb(255, 242, 241);
	}
	.title-full {
		display: flex;
		align-items: center;
		flex:none;
		font-size: 1.5rem;
	}
	.toggle-button {
		display: none;
	}
	.full-nav {
		display: flex;
		text-align: center;
		font-size: 1.5rem;
	}
	.full-nav a {
		padding:.5rem 1rem .1rem 1rem;
		border-radius: 40% 40% 0 0;
		-webkit-transition: background-color .4s ease-in-out;
		transition: background-color .4s ease-in-out;
	}
	.current-page {
		background-color: rgb(255, 242, 241);
		border-radius: 40% 40% 0 0;
		-webkit-transition: background-color .4s ease-in-out;
		transition: background-color .4s ease-in-out;

	}

	.mobile-spacer {
		flex: none;
	}
	.slider {
		position: relative;
		max-width: 500px;
		max-height: 500px;
	}
	.shown,
	.hidden {
		display: none;
	}
	.mobile-nav {
	display: none;
	}
}
